<template>
    <div class="body-bg">
        <div class="-default"></div>
    </div>
</template>

<script>
    export default {
        name: 'BgBody'
    }
</script>